import axios from 'axios';
import { config } from '../config';

const getApiPricing = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/api/pricing`,
			{

			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getPartnerProfilePhotoProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/partner/profile/photo`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getPartnerComplianceDocumentsProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/partner/compliance/documents`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateProfilePhoto = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/profile/photo`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateName = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEmail = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateDescription = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/description`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateSector = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/sector`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateMasterToken = async function (token) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/update/token`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateLiveApiKey = async function (token) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/update/live/api/key`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateTestApiKey = async function (token) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/update/test/api/key`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/compliance/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data, response_code: response.status };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceCertificate = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/compliance/certificate`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDocument = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/compliance/document`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDocuments = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/compliance/documents`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const changePassword = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/password/change`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { 
	getApiPricing, updateComplianceCertificate, updateComplianceDetails, updateComplianceDocument, updateComplianceDocuments, 
	updateDescription, updateEmail, updateLiveApiKey, updateMasterToken, updateName, updateProfilePhoto, updateTestApiKey, 
	getPartnerProfilePhotoProof, getPartnerComplianceDocumentsProof, changePassword, updateSector
};