import axios from 'axios';
import { config } from '../config';

const partnerSignup = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/signup`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resendVerificationEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/resend/verification/email`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const loginViaEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/access`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const verifyEmailOtp = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/access/verify`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const loginViaToken = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/access/token`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resetMasterToken = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/partner/reset/token`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resetPassword = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/password/reset`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const verifyEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/verify/email`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { partnerSignup, loginViaEmail, loginViaToken, resendVerificationEmail, resetMasterToken, verifyEmailOtp, verifyEmail, resetPassword };