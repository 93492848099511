import axios from 'axios';
import { config } from '../config';

const getAccessDetails = async function (stripped) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/access/details`,
			{
				stripped
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getPartner = async function (token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerMetrics = async function (token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/metrics`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerBalance = async function (token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/balance`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getCompanyBankAccount = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/company/bank/account`,
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getAccessDetails, getPartner, getPartnerBalance, getPartnerMetrics, getCompanyBankAccount };