const { 
	INTERNAL_KEY, FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_DATABASE_URL, FIREBASE_MEASUREMENT_ID, 
	FIREBASE_MESSAGING_SENDER_ID, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, PAYSTACK_PUBLIC_API_KEY
} = require("../hidden-partners.json");

const EMAIL_REGEX = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
const baseAPIurl = "https://us-central1-passcoder-identity.cloudfunctions.net/endpoints/ng_app_internal";
const internalKey = INTERNAL_KEY;
const firebaseConfig = {
	apiKey: FIREBASE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
	appId: FIREBASE_APP_ID,
	measurementId: FIREBASE_MEASUREMENT_ID
};

const random_numbers = (length) => {
	if (length === undefined || length === null || length === 0) {
		return 0;
	} else {
		let rand_number = "";
		for (let index = 0; index < length; index++) {
			rand_number += Math.floor(Math.random() * 10);
		}
		return rand_number;
	}
};

const charge_price = 50;

export const config = {
	token: "encrypted_token",
	EMAIL_REGEX,
	baseAPIurl,
	internalKey,
	firebaseConfig,
	paystackPublicApiKey: PAYSTACK_PUBLIC_API_KEY, 
	uuid: random_numbers,
	charge_price
};