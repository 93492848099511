export default function Documentation() {
	return (
		<>
			<div className="xui-form-box xui-w-fluid-100 xui-mt-1">
				<h6 className='xui-font-sz-100 xui-font-w-normal'>Visit our documentation page for a full overview of how our API works</h6>
				<a target="_blank" href="https://documenter.getpostman.com/view/16645752/2s93z9cNvb" className="xui-btn xui-mt-2 psc-btn-blue xui-font-sz-80">
					Go to documentation
				</a>
			</div>
		</>
	);
}