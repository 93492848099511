import axios from 'axios';
import { config } from '../config';

const getPartnerTokens = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/tokens`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getPartnerToken = async function (token, unique_token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/token`,
			{
				token: unique_token
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addPartnerToken = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/partner/token/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateTokenDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/token/update/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateToken = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/partner/token/update/token`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const deleteToken = async function (token, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/partner/token`,
			{
				data: {
					token,
					...payload
				}
			},
			// {
			// 	headers: {
			// 		'passcoder-access-token': token
			// 	}
			// }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getPartnerToken, addPartnerToken, deleteToken, getPartnerTokens, updateToken, updateTokenDetails };
