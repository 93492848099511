import { useState } from "react";
import { Link } from "react-router-dom";
import { usePartnerSignUp } from '../../hooks/useAuth';
import Loading from "../../icons/Loading";
import SuccessTick from "../../assets/images/success-tick.png";
import Arrowright from "../../icons/Arrowright";
import EyeOpen from "../../icons/EyeOpen";
import EyeClose from "../../icons/EyeClose";

export default function SignUp(){
	const {
		country, description, email, errorPartnerSignup, handleCountry, handleDescription, handleEmail, password, 
		handleHospitality, handleName, handleSubmit, handleTermsAndConditions, sector, loading, name, handleSector, 
		successPartnerSignup, terms_and_conditions, loadingResend, showVerificationEmail, errorVerificationEmail, 
		handleVerificationEmailResend, successVerificationEmail, confirmPassword, handleConfirmPassword, handlePassword
	} = usePartnerSignUp();

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return(
        <>
			<div className="xui-max-w-700 xui-w-fluid-100 xui-mt-2 xui-md-mt-none">
				{
					!showVerificationEmail ? 
					<div className="xui-bg-white xui-bdr-rad-half xui-w-fluid-100 xui-p-1-half xui-pb-3 xui-text-black">
						<h2 className="xui-font-sz-125 xui-w-fluid-80">Sign up for Passcoder Partner</h2>
						<p className="xui-font-sz-80 xui-my-1"><span className="xui-opacity-7">Already have an account?</span> <Link to={`/access`} className="xui-font-w-bold psc-text xui-text-dc-none">Login</Link></p>
						<form className="xui-form" layout="2" onSubmit={handleSubmit}>
							<div className="xui-form-box xui-mt-2">
								<input className="xui-font-sz-90" type="text" value={name} onChange={handleName} required placeholder="Partner Name"></input>
							</div>
							<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
								<div className="xui-form-box xui-mt-1">
									<input className="xui-font-sz-90" type="email" value={email} onChange={handleEmail} required placeholder="Email"></input>
								</div>
								<div className="xui-form-box xui-mt-1">
									<select onChange={handleCountry} value={country} required>
										<option selected disabled>Select Country</option>
										<option value={"Nigeria"}>Nigeria</option>
									</select>
								</div>
							</div>
							<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
								<div className="xui-mt-1 xui-d-inline-flex xui-flex-ai-center xui-w-fluid-100">
									<input className="xui-font-sz-90" type={showPassword ? "text" : "password"} value={password} onChange={handlePassword} required placeholder="Password"></input>
									<span className="xui-cursor-pointer" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOpen width="20" height="20" /> : <EyeClose width="20" height="20" />}</span>
								</div>
								<div className="xui-mt-1 xui-d-inline-flex xui-flex-ai-center xui-w-fluid-100">
									<input className="xui-font-sz-90" type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={handleConfirmPassword} required placeholder="Confirm Password"></input>
									<span className="xui-cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <EyeOpen width="20" height="20" /> : <EyeClose width="20" height="20" />}</span>
								</div>
							</div>
							<div className="xui-form-box xui-mt-2">
								<select onChange={handleSector} value={sector} required>
									<option selected disabled>Select Sector</option>
									<option value="Aerospace">Aerospace</option>
									<option value="Agricultural">Agricultural</option>
									<option value="Automotive">Automotive</option>
									<option value="Basic metal">Basic metal</option>
									<option value="Chemical">Chemical</option>
									<option value="Computer">Computer</option>
									<option value="Construction">Construction</option>
									<option value="Creative">Creative</option>
									<option value="Cultural">Cultural</option>
									<option value="Defense">Defense</option>
									<option value="Education">Education</option>
									<option value="Electric power">Electric power</option>
									<option value="Electronics">Electronics</option>
									<option value="Energy">Energy</option>
									<option value="Engineering">Engineering</option>
									<option value="Entertainment">Entertainment</option>
									<option value="Farming">Farming</option>
									<option value="Fashion">Fashion</option>
									<option value="Film">Film</option>
									<option value="Financial services">Financial services</option>
									<option value="Fishing">Fishing</option>
									<option value="Food">Food</option>
									<option value="Forestry">Forestry</option>
									<option value="Gambling">Gambling</option>
									<option value="Gas">Gas</option>
									<option value="Green">Green</option>
									<option value="Health services">Health services</option>
									<option value="Hospitality">Hospitality</option>
									<option value="Hotels">Hotels</option>
									<option value="Industrial robot">Industrial robot</option>
									<option value="Information">Information</option>
									<option value="Information technology">Information technology</option>
									<option value="Infrastructure">Infrastructure</option>
									<option value="Insurance">Insurance</option>
									<option value="Leisure">Leisure</option>
									<option value="Low technology">Low technology</option>
									<option value="Manufacturing">Manufacturing</option>
									{/* <option value="Meat">Meat</option> */}
									<option value="Media">Media</option>
									<option value="Merchandising">Merchandising</option>
									<option value="Mining">Mining</option>
									<option value="Music">Music</option>
									<option value="News media">News media</option>
									<option value="Oil and gas">Oil and gas</option>
									<option value="Pharmaceutical">Pharmaceutical</option>
									<option value="Professional">Professional</option>
									<option value="Publishing">Publishing</option>
									<option value="Pulp and paper">Pulp and paper</option>
									<option value="Railway">Railway</option>
									<option value="Real estate">Real estate</option>
									<option value="Retail">Retail</option>
									<option value="Scientific">Scientific</option>
									<option value="Services">Services</option>
									{/* <option value="Sex">Sex</option> */}
									<option value="Software">Software</option>
									<option value="Space">Space</option>
									<option value="Sport">Sport</option>
									<option value="Steel">Steel</option>
									<option value="Technology">Technology</option>
									<option value="Telecommunications">Telecommunications</option>
									<option value="Textile">Textile</option>
									{/* <option value="Tobacco">Tobacco</option> */}
									<option value="Transport">Transport</option>
									<option value="Utility">Utility</option>
									<option value="Video game">Video game</option>
									<option value="Water">Water</option>
									<option value="Wholesale">Wholesale</option>
									<option value="Wood">Wood</option>
								</select>
							</div>
							<div className="xui-form-box xui-mt-2">
								<textarea className="xui-font-sz-90" value={description} onChange={handleDescription} required cols={20} rows={20} placeholder="Description"></textarea>
							</div>
							<p className="xui-font-sz-80 xui-my-1 xui-text-center">
								<div className="xui-d-inline-flex xui-flex-ai-center">
									<input type="checkbox" onChange={handleTermsAndConditions} checked={terms_and_conditions} id="terms_and_conditions" />
									<label for="terms_and_conditions" className="xui-ml-half" style={{ marginBottom: '0' }}>By signing up you agree to our <a href="https://passcoder.io/terms">terms and conditions</a> </label>
								</div>
							</p>
							<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Get access</span>
									{
										loading ?
											<Loading width="12" height="12" />
											: <Arrowright width="12" height="12" />
									}
								</button>
							</div>
						</form>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPartnerSignup}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPartnerSignup}</span></p>
					</div> : 
					<div className="xui-bg-white xui-bdr-rad-half xui-w-fluid-100 xui-p-1-half xui-pb-3 xui-text-black">
						<div className="xui-my-3">
							<img src={SuccessTick} className="xui-img-100 xui-mx-auto" alt="success-tick" />
							<h2 className="xui-font-sz-125 xui-w-fluid-80 xui-mx-auto xui-text-center xui-mt-1-half">Verification email sent</h2>
							<p className="xui-font-sz-90 xui-w-fluid-80 xui-mx-auto xui-text-center xui-mt-1-half">We have sent an email with a verification link to {email}.</p>
						</div>
							<p className="xui-font-sz-90 xui-my-3 xui-text-center xui-opacity-7"><span className="xui-opacity-7">Didn't receive the email? Check spam or promotion folder. <Link to={`/access`} className="xui-font-w-bold psc-text xui-text-dc-none">Login now</Link></span></p>

						<div className="xui-mt-3 xui-d-flex xui-flex-jc-flex-end">
								<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" onClick={handleVerificationEmailResend}>
								<span className="xui-mr-half">{loadingResend ? "Resending" : "Resend Link"}</span>
								{
									loadingResend ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerificationEmail}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerificationEmail}</span></p>
					</div>
				}
			</div>
        </>
    )
}