import { useState } from "react";
import { usePaystackPayment } from 'react-paystack';
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addPartnerDeposit, cancelPartnerDeposit, completePartnerDeposit, deletePartnerTransaction } from "../api/transactions";

const useAddDeposit = () => {

	const {cookie} = useCookie(config.token, "");

	const [partnerDetails, setPartnerDetails] = useState(null);
	const [depositReference, setDepositReference] = useState(null);

	const general_min_amount = 5000;
	const credit_card_max_amount = 20000;
	const transfer_max_amount = 100000;

	const [loading, setLoading] = useState(false);
	const [loadingRetryDepositCheck, setLoadingRetryDepositCheck] = useState(false);
	const [removeFundingModal, setRemoveFundingModal] = useState(null);
	const [showSuccessfulDepositModal, setShowSuccessfulDepositModal] = useState(null);
	const [showUnsuccessfulDepositModal, setShowUnsuccessfulDepositModal] = useState(null);
	const [fundingAmount, setFundingAmount] = useState(null);
	const [fundingPaymentMethod, setFundingPaymentMethod] = useState("Credit/Debit Card");
	const [minFundingAmount, setMinFundingAmount] = useState(general_min_amount);
	const [maxFundingAmount, setMaxFundingAmount] = useState(credit_card_max_amount);

	const [errorAddDeposit, setErrorAddDeposit] = useState(null);
	const [successAddDeposit, setSuccessAddDeposit] = useState(null);

	const [paystackConfig, setPaystackConfig] = useState({
		publicKey: config.paystackPublicApiKey,
		reference: "TXB" + config.uuid(12)
	});

	const initializePayment = usePaystackPayment(paystackConfig);

	const handleFundingAmount = (e) => { 
		e.preventDefault(); 
		setFundingAmount(e.target.value); 
		setPaystackConfig({
			...paystackConfig,
			amount: (parseInt(e.target.value) * 100) + ((config.charge_price * 2) * 100),
		});
	};
	const handleFundingPaymentMethod = (e) => { e.preventDefault(); setFundingPaymentMethod(fundingPaymentMethod === "Credit/Debit Card" ? "Transfer" : "Credit/Debit Card"); if (fundingPaymentMethod === "Credit/Debit Card") setMaxFundingAmount(transfer_max_amount); if (fundingPaymentMethod !== "Credit/Debit Card") setMaxFundingAmount(credit_card_max_amount) };
	const handlePartnerDetails = (details) => { 
		setPartnerDetails(details);  
		setPaystackConfig({
			...paystackConfig,
			email: details.email,
		});
	};

	const retryDepositCheck = (reference) => {
		if (!reference) {
			setErrorAddDeposit("Deposit reference required");
		} else {
			setErrorAddDeposit(null);
			setLoadingRetryDepositCheck(true);

			const completeDepositRes = completePartnerDeposit(cookie, {
				unique_id: reference
			})
	
			completeDepositRes.then(res => {
				setLoadingRetryDepositCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorAddDeposit(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorAddDeposit(error);
					}
				} else {
					setSuccessAddDeposit(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryDepositCheck(false);
				const error = `An error occured`;
				setErrorAddDeposit(error);
			})
		}
	};

	const retryDepositCheckAlt = (reference) => {
		setShowSuccessfulDepositModal(true);
		if (!reference) {
			setErrorAddDeposit("Deposit reference required");
		} else {
			setErrorAddDeposit(null);
			setLoadingRetryDepositCheck(true);

			const completeDepositRes = completePartnerDeposit(cookie, {
				unique_id: reference
			})

			completeDepositRes.then(res => {
				setLoadingRetryDepositCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorAddDeposit(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorAddDeposit(error);
					}
				} else {
					setSuccessAddDeposit(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryDepositCheck(false);
				const error = `An error occured`;
				setErrorAddDeposit(error);
			})
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		if (!loading) {
			if (fundingAmount < minFundingAmount) {
				setErrorAddDeposit(null);
				setSuccessAddDeposit(null);
				setErrorAddDeposit(`Minimum amount is NGN ${minFundingAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else if (fundingAmount > maxFundingAmount) {
				setErrorAddDeposit(`Maximum amount is NGN ${maxFundingAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else if (!fundingPaymentMethod) {
				setErrorAddDeposit("Payment Method is Required");
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else {
				setLoading(true);
	
				const addDepositRes = addPartnerDeposit(cookie, {
					amount: parseInt(fundingAmount),
					payment_method: fundingPaymentMethod,
					reference: paystackConfig.reference
				})
	
				addDepositRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							setLoading(false);
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddDeposit(error);
							setTimeout(function () {
								setErrorAddDeposit(null);
							}, 2000)
						} else {
							setLoading(false);
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddDeposit(error);
							setTimeout(function () {
								setErrorAddDeposit(null);
							}, 2000)
						}
					} else {
						setErrorAddDeposit(null);
						setDepositReference(res.data.data.unique_id);
						
						if (fundingPaymentMethod === "Transfer") {
							setLoading(false);
							setSuccessAddDeposit(`Transaction added successfully!`);
							setTimeout(function () {
								setSuccessAddDeposit(null);
								setRemoveFundingModal(true);
								setFundingAmount(0);
							}, 2500)
						} else {
							setTimeout(function () {
								setLoading(false);

								const onPaystackSuccess = (reference) => {
									setShowSuccessfulDepositModal(true);
									const completeDepositRes = completePartnerDeposit(cookie, {
										unique_id: res.data.data.unique_id
									})

									completeDepositRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setErrorAddDeposit(error);
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setErrorAddDeposit(error);
											}
										} else {
											setSuccessAddDeposit(`Transaction completed successfully!`);
										}
									}).catch(err => {
										const error = `An error occured`;
										setErrorAddDeposit(error);
									})
								};

								const onPaystackClose = () => {
									setShowUnsuccessfulDepositModal(true);
									const cancelDepositRes = cancelPartnerDeposit(cookie, {
										unique_id: res.data.data.unique_id
									})

									cancelDepositRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setErrorAddDeposit(error);
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setErrorAddDeposit(error);
											}
										} else {
											setErrorAddDeposit(`Transaction cancelled successfully!`);
										}
									}).catch(err => {
										const error = `An error occured`;
										setErrorAddDeposit(error);
									})
								};

								setSuccessAddDeposit(null);
								setRemoveFundingModal(true);
								setFundingAmount(0);
								initializePayment(onPaystackSuccess, onPaystackClose);
							}, 2500)
						}
					}
				}).catch(err => {
					setLoading(false);
				})
	
			}
		}
	};

	return {
		cookie, loading, removeFundingModal, fundingAmount, fundingPaymentMethod, errorAddDeposit, successAddDeposit, setSuccessAddDeposit, 
		handleFundingAmount, handleFundingPaymentMethod, handleSubmit, setRemoveFundingModal, handlePartnerDetails, setErrorAddDeposit, 
		showSuccessfulDepositModal, setShowSuccessfulDepositModal, showUnsuccessfulDepositModal, setShowUnsuccessfulDepositModal, 
		depositReference, retryDepositCheck, loadingRetryDepositCheck, retryDepositCheckAlt
	};
};

const useCancelDeposit = () => {

	const {cookie} = useCookie(config.token, "");

	const [loadingCancelDeposit, setLoadingCancelDeposit] = useState(false);
	const [removeCancelDepositModal, setRemoveCancelDepositModal] = useState(null);
	const [cancelDepositUniqueId, setCancelDepositUniqueId] = useState(null);

	const [errorCancelDeposit, setErrorCancelDeposit] = useState(null);
	const [successCancelDeposit, setSuccessCancelDeposit] = useState(null);

	const handleCancelDeposit = () => {

		if (!loadingCancelDeposit) {
			if (!cancelDepositUniqueId) {
				setErrorCancelDeposit(null);
				setSuccessCancelDeposit(null);
				setErrorCancelDeposit("Unique ID is required");
				setTimeout(function () {
					setErrorCancelDeposit(null);
				}, 2500)
			} else {
				setLoadingCancelDeposit(true);
	
				const cancelDepositRes = cancelPartnerDeposit(cookie, {
					unique_id: cancelDepositUniqueId
				})
	
				cancelDepositRes.then(res => {
					setLoadingCancelDeposit(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCancelDeposit(error);
							setTimeout(function () {
								setErrorCancelDeposit(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCancelDeposit(error);
							setTimeout(function () {
								setErrorCancelDeposit(null);
							}, 2000)
						}
					} else {
						setErrorCancelDeposit(null);
						setSuccessCancelDeposit(`Transaction cancelled successfully!`);
	
						setTimeout(function () {
							setSuccessCancelDeposit(null);
							setRemoveCancelDepositModal(true);
							setCancelDepositUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCancelDeposit(false);
				})
	
			}
		}
	};

	return {
		cookie, loadingCancelDeposit, removeCancelDepositModal, cancelDepositUniqueId, errorCancelDeposit, successCancelDeposit,
		handleCancelDeposit, setRemoveCancelDepositModal, setCancelDepositUniqueId
	};
};

export { useAddDeposit, useCancelDeposit };