import { useState, useEffect } from "react";
import Close from "../../icons/Close";
import GalleryAdd from "../../assets/images/gallery-add.png";
import Check from "../../icons/Check";
import Loading from "../../icons/Loading";
import { useUpdateDescription, useUpdateEmail, useUpdateName, useUploadPartnerProfilePhoto, useUpdateSector } from "../../hooks/useSettings";
import { useGetPartner } from "../../hooks/usePartner";

export default function AccountProfile(){
    const [canCallPartnerDetails, setCanCallPartnerDetails] = useState(false);
    const [callUseEffect, setCallUseEffect] = useState(true);

    const {
        partnerName, errorUpdateName, handlePartnerName, handleUpdateName, loadingUpdateName, setPartnerName, successUpdateName, 
        removeUpdateNameModal, setRemoveUpdateNameModal
    } = useUpdateName();

    const {
        partnerEmail, errorUpdateEmail, handlePartnerEmail, handleUpdateEmail, loadingUpdateEmail, setPartnerEmail, successUpdateEmail, 
        removeUpdateEmailModal, setRemoveUpdateEmailModal, handleStripped
    } = useUpdateEmail();

    const {
        partnerDescription, errorUpdateDescription, handlePartnerDescription, handleUpdateDescription, loadingUpdateDescription, 
        setPartnerDescription, successUpdateDescription
    } = useUpdateDescription();

    const {
        partnerSector, errorUpdateSector, handlePartnerSector, handleUpdateSector, loadingUpdateSector,
        setPartnerSector, successUpdateSector
    } = useUpdateSector();

    const {
        getPartnerDetails, partnerDetails
    } = useGetPartner();

    const {
        errorProfilePhoto, handleUploadProfilePhoto, loadingProfilePhoto, setPartnerUniqueId, setSelectedProfilePhoto, successProfilePhoto, 
        uploadingProfilePhotoPercentage, selectedProfilePhoto
    } = useUploadPartnerProfilePhoto();

    const callGetPartnerDetails = getPartnerDetails;

    if (successUpdateName || successUpdateEmail || successUpdateDescription || successUpdateSector || successProfilePhoto) callGetPartnerDetails();

    if (canCallPartnerDetails) { 
        setTimeout(function () {
            callGetPartnerDetails(); 
            setCanCallPartnerDetails(false);
        }, 2000)
    }

    if (removeUpdateEmailModal) {
        const modalResponse = document.querySelector("#confirmEmailUpdate");
        modalResponse.setAttribute("display", false);
        callGetPartnerDetails(); 
        setRemoveUpdateEmailModal(null);
    }
    if (removeUpdateNameModal) {
        const modalResponse = document.querySelector("#confirmNameUpdate");
        modalResponse.setAttribute("display", false);
        callGetPartnerDetails(); 
        setRemoveUpdateNameModal(null);
    }

    const handleSelectProfilePhoto = (e) => {
        const el = e.target.files[0];
        setSelectedProfilePhoto("");
        setSelectedProfilePhoto(el);
    }

    // useEffect(() => {
    //     if (callUseEffect) {
    //         if (partnerDetails !== null) {
    //             setPartnerName(partnerDetails.data.name);
    //             setPartnerEmail(partnerDetails.data.email);
    //             setPartnerDescription(partnerDetails.data.description);
    //         }
    //     }
    // }, [partnerDetails, callUseEffect]);
    return(
        <>
            <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1">
                <form className="xui-form" onSubmit={handleUploadProfilePhoto}>
                    <div className="xui-form-box">
                        <center>
                            <span className="xui-d-inline-block">Your Profile Picture</span>
                            <label htmlFor="profilePhoto">
                                <div className="xui-w-250 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
                                    {
                                        selectedProfilePhoto ?
                                            <img className="xui-img-150" src={URL.createObjectURL(selectedProfilePhoto)} alt="" /> :
                                            <>
                                                {
                                                    partnerDetails ? 
                                                        <img className="xui-img-150" src={partnerDetails.data.photo} alt="" /> : 
                                                        <img className="xui-img-40" src={GalleryAdd} alt="" />
                                                }
                                                <span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
                                            </>
                                    }
                                </div>
                            </label>
                            <input onClick={() => { if (partnerDetails) setPartnerUniqueId(partnerDetails.data.partner_unique_id) }} onChange={handleSelectProfilePhoto} type={"file"} id="profilePhoto" style={{display:"none"}} required />
                            <div className="xui-mt-1">
                                {
                                    uploadingProfilePhotoPercentage > 0 ? 
                                    <>
                                        <label htmlFor="uploader">Uploading</label>
                                        <progress className="xui-h-30" value={uploadingProfilePhotoPercentage} id="uploader" max="100">{uploadingProfilePhotoPercentage + "%"}</progress><br/><br></br>
                                    </> :
                                    ""
                                }
                                {
                                    loadingProfilePhoto ? 
                                    <button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
                                        <Loading width="16" height="16" />
                                    </button> :
                                    <button type="submit" className="xui-btn psc-btn-blue xui-font-sz-80">
                                        Save Changes
                                    </button>
                                }
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorProfilePhoto}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successProfilePhoto}</span></p>
                        </center>
                    </div>
                </form>
                <div className="xui-form-box" style={{ marginTop: "-5px" }}>
                    <form className="xui-form">
                        <div className="xui-form-box xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Partner Name</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                            <input style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={partnerDetails ? partnerDetails.data.name : ""} readOnly={partnerDetails ? false : true} value={partnerName} onClick={() => { if (partnerDetails) setPartnerName(partnerDetails.data.name) }} onChange={handlePartnerName} required />
                                <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open="confirmNameUpdate">
                                    <Check width="16" height="16" />
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateName}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateName}</span></p>
                        </div>
                    </form>
                    <form className="xui-form">
                        <div className="xui-form-box xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Partner Email</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                            <input style={{ width: "calc(100% - 50px)" }} type={"email"} placeholder={partnerDetails ? partnerDetails.data.email : ""} readOnly={partnerDetails ? false : true} value={partnerEmail} onClick={() => { if (partnerDetails) setPartnerEmail(partnerDetails.data.email) }} onChange={handlePartnerEmail} required />
                                <div onClick={() => handleStripped(partnerDetails ? partnerDetails.data.stripped : "")} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open="confirmEmailUpdate">
                                    <Check width="16" height="16" />
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateEmail}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateEmail}</span></p>
                        </div>
                    </form>
                    <form className="xui-form">
                        <div className="xui-form-box xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Partner Description</label>
                            <div className="xui-d-flex xui-flex-ai-flex-end">
                                <textarea style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={partnerDetails ? partnerDetails.data.description : ""} readOnly={partnerDetails ? false : true} value={partnerDescription} onClick={() => { if (partnerDetails) setPartnerDescription(partnerDetails.data.description) }} onChange={handlePartnerDescription} required></textarea>
                                <div onClick={handleUpdateDescription} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
                                    {
                                        loadingUpdateDescription ?
                                            <Loading width="16" height="16" />
                                            : <Check width="16" height="16" />
                                    }
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateDescription}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateDescription}</span></p>
                        </div>
                    </form>
                    <form className="xui-form">
                        <div className="xui-form-box xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Sector</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                                <select style={{ width: "calc(100% - 50px)" }} onChange={handlePartnerSector} required>
                                    <option selected disabled>Select Sector</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Aerospace" ? true : false) : false} value="Aerospace">Aerospace</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Agricultural" ? true : false) : false} value="Agricultural">Agricultural</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Automotive" ? true : false) : false} value="Automotive">Automotive</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Basic metal" ? true : false) : false} value="Basic metal">Basic metal</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Chemical" ? true : false) : false} value="Chemical">Chemical</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Computer" ? true : false) : false} value="Computer">Computer</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Construction" ? true : false) : false} value="Construction">Construction</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Creative" ? true : false) : false} value="Creative">Creative</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Cultural" ? true : false) : false} value="Cultural">Cultural</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Defense" ? true : false) : false} value="Defense">Defense</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Education" ? true : false) : false} value="Education">Education</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Electric power" ? true : false) : false} value="Electric power">Electric power</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Electronics" ? true : false) : false} value="Electronics">Electronics</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Energy" ? true : false) : false} value="Energy">Energy</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Engineering" ? true : false) : false} value="Engineering">Engineering</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Entertainment" ? true : false) : false} value="Entertainment">Entertainment</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Farming" ? true : false) : false} value="Farming">Farming</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Fashion" ? true : false) : false} value="Fashion">Fashion</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Film" ? true : false) : false} value="Film">Film</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Financial services" ? true : false) : false} value="Financial services">Financial services</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Fishing" ? true : false) : false} value="Fishing">Fishing</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Food" ? true : false) : false} value="Food">Food</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Forestry" ? true : false) : false} value="Forestry">Forestry</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Gambling" ? true : false) : false} value="Gambling">Gambling</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Gas" ? true : false) : false} value="Gas">Gas</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Green" ? true : false) : false} value="Green">Green</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Health services" ? true : false) : false} value="Health services">Health services</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Hospitality" ? true : false) : false} value="Hospitality">Hospitality</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Hotels" ? true : false) : false} value="Hotels">Hotels</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Industrial robot" ? true : false) : false} value="Industrial robot">Industrial robot</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Information" ? true : false) : false} value="Information">Information</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Information technology" ? true : false) : false} value="Information technology">Information technology</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Infrastructure" ? true : false) : false} value="Infrastructure">Infrastructure</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Insurance" ? true : false) : false} value="Insurance">Insurance</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Leisure" ? true : false) : false} value="Leisure">Leisure</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Low technology" ? true : false) : false} value="Low technology">Low technology</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Manufacturing" ? true : false) : false} value="Manufacturing">Manufacturing</option>
                                    {/* <option value="Meat">Meat</option> */}
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Media" ? true : false) : false} value="Media">Media</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Merchandising" ? true : false) : false} value="Merchandising">Merchandising</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Mining" ? true : false) : false} value="Mining">Mining</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Music" ? true : false) : false} value="Music">Music</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "News media" ? true : false) : false} value="News media">News media</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Oil and gas" ? true : false) : false} value="Oil and gas">Oil and gas</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Pharmaceutical" ? true : false) : false} value="Pharmaceutical">Pharmaceutical</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Professional" ? true : false) : false} value="Professional">Professional</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Publishing" ? true : false) : false} value="Publishing">Publishing</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Pulp and paper" ? true : false) : false} value="Pulp and paper">Pulp and paper</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Railway" ? true : false) : false} value="Railway">Railway</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Real estate" ? true : false) : false} value="Real estate">Real estate</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Retail" ? true : false) : false} value="Retail">Retail</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Scientific" ? true : false) : false} value="Scientific">Scientific</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Services" ? true : false) : false} value="Services">Services</option>
                                    {/* <option value="Sex">Sex</option> */}
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Software" ? true : false) : false} value="Software">Software</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Space" ? true : false) : false} value="Space">Space</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Sport" ? true : false) : false} value="Sport">Sport</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Steel" ? true : false) : false} value="Steel">Steel</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Technology" ? true : false) : false} value="Technology">Technology</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Telecommunications" ? true : false) : false} value="Telecommunications">Telecommunications</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Textile" ? true : false) : false} value="Textile">Textile</option>
                                    {/* <option value="Tobacco">Tobacco</option> */}
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Transport" ? true : false) : false} value="Transport">Transport</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Utility" ? true : false) : false} value="Utility">Utility</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Video game" ? true : false) : false} value="Video game">Video game</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Water" ? true : false) : false} value="Water">Water</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Wholesale" ? true : false) : false} value="Wholesale">Wholesale</option>
                                    <option selected={partnerDetails ? (partnerDetails.data.sector === "Wood" ? true : false) : false} value="Wood">Wood</option>
                                </select>
                                <div onClick={handleUpdateSector} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
                                    {
                                        loadingUpdateSector ?
                                            <Loading width="16" height="16" />
                                            : <Check width="16" height="16" />
                                    }
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateSector}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateSector}</span></p>
                        </div>
                    </form>
                </div>
            </div>
            <section className='xui-modal' xui-modal="confirmNameUpdate" id="confirmNameUpdate">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1 className="5 xui-font-sz-120 xui-mb-2">Update Partner Name</h1>
                        <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half xui-mb-2">Are you sure you want to continue with this action?</p>
                        {/* <p className="xui-opacity-5 xui-font-sz-90 xui-text-red xui-mt-half">We will log you out and you'll have to use your new private access url to log in ...</p> */}
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateName}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateName}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleUpdateName} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingUpdateName ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUpdateName ? "" : "confirmNameUpdate"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="confirmEmailUpdate" id="confirmEmailUpdate">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1 className="5 xui-font-sz-120 xui-mb-2">Update Partner Email</h1>
                        <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half xui-mb-2">Are you sure you want to continue with this action?</p>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-text-red xui-mt-half">We will log you out and you'll have to verify your new email to log in ...</p>
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateEmail}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateEmail}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleUpdateEmail} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingUpdateEmail ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUpdateEmail ? "" : "confirmEmailUpdate"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}