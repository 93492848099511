import { useState } from "react";
import Check from "../../icons/Check";
import Copy from "../../icons/Copy";
import Reset from "../../icons/Reset";
import EyeOpen from "../../icons/EyeOpen";
import EyeClose from "../../icons/EyeClose";
import Loading from "../../icons/Loading";
import { useChangePassword } from "../../hooks/useSettings";
import Close from "../../icons/Close";

export default function MasterPassword() {

	const {
		confirmPassword, errorChangePassword, handleChangePassword, handleConfirmPassword, handlePassword, 
		loadingChangePassword, password, setConfirmPassword, setPassword, successChangePassword
	} = useChangePassword();

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	return (
		<>
			<div className="xui-form-box xui-w-fluid-100 xui-mt-1">
				<form className="xui-form" layout="2" onSubmit={(e) => e.preventDefault()}>
					<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1 xui-mb-2">
						<div className="xui-mt-1">
							<label>Password</label>
							<div className="xui-mb-2 xui-d-inline-flex xui-flex-ai-center xui-w-fluid-100">
								<input className="xui-font-sz-90" type={showPassword ? "text" : "password"} value={password} onChange={handlePassword} required placeholder="Enter Password"></input>
								<span className="xui-cursor-pointer" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOpen width="20" height="20" /> : <EyeClose width="20" height="20" />}</span>
							</div>
						</div>
						<div className="xui-mt-1">
							<label>Confirm Password</label>
							<div className="xui-mb-2 xui-d-inline-flex xui-flex-ai-center xui-w-fluid-100">
								<input className="xui-font-sz-90" type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={handleConfirmPassword} required placeholder="Enter Confirm Password"></input>
								<span className="xui-cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <EyeOpen width="20" height="20" /> : <EyeClose width="20" height="20" />}</span>
							</div>
						</div>
					</div>
					<button onClick={handleChangePassword} className="xui-btn xui-mt-1 psc-btn-blue xui-font-sz-80">
						{
							loadingChangePassword ?
								<Loading width="12" height="12" />
								: "Save Changes"
						}
					</button>
					<p className="xui-font-sz-100 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorChangePassword}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successChangePassword}</span></p>
				</form>
			</div>
		</>
	);
}